<template>
    <div class="wrap">
        <!-- 海报html元素 -->
        <div id="posterHtml" class="bgImage" :style="{backgroundImage: 'url('+posterHtmlBg+')'}">
            <!-- 二维码 -->
            <div class="qrcode">
                <div id="qrcodeImg" class="imgStyle"></div>
            </div>
        </div>
        <button class="baocun" @click="createPoster">点击保存至相册,分享到朋友圈</button>
    </div>
</template>
  
<script>

import wx from "weixin-js-sdk-ts";
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'

export default {
    components: {},
    data() {
        return {
            posterImg: '', // 最终生成的海报图片
            posterImgName: '宣传海报', // 最终生成的海报图片名称
            posterHtmlBg: require('../../assets/img/poster_bg.png'), // 背景图
        };
    },
    methods: {
        createQrcode(text) {
            // 生成二维码
            const qrcodeImgEl = document.getElementById('qrcodeImg')
            let qrcode = new QRCode(qrcodeImgEl, {
                width: 120,
                height: 120,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
            qrcode.makeCode(text)
        },
        createPoster() {                //click 点击按钮生成海报
            // 生成海报
            const vm = this
            const domObj = document.getElementById('posterHtml')
            html2canvas(domObj, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                letterRendering: true,
                onclone(doc) {
                    let e = doc.querySelector('#posterHtml')
                    e.style.display = 'block'
                }
            }).then(canvas => {
                // 在微信里,可长按保存或转发
                vm.posterImg = canvas.toDataURL('image/png')
                vm.imgDown(vm.posterImg)
            })
        },
        // 保存图片到本地
        imgDown(img) {
            console.log('img ===',img)
            var ua = navigator.userAgent.toLowerCase()
            if (ua.match(/MicroMessenger/i) === 'micromessenger' || ua.indexOf('micromessenger') !== -1) {
            //第一种情况在微信小程序中没有奏效，第二种奏效了
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                wx.miniProgram.getEnv((res) => {
                   this.saveImg(img)
                })
            } else {
                var alink = document.createElement('a')
                alink.href = img
                alink.download = this.posterImgName // 图片名
                document.body.appendChild(alink)
                alink.click()
                
                // this.savePic(img)
            }
        },
        savePic(Url){
            // Url = this.dialogImgUrl //图片路径，也可以传值进来
            var triggerEvent = "touchstart"; //指定下载方式
            var blob=new Blob([''], {type:'application/octet-stream'}); //二进制大型对象blob
            var url = URL.createObjectURL(blob); //创建一个字符串路径空位
            var a = document.createElement('a'); //创建一个 a 标签
            a.href = Url;  //把路径赋到a标签的href上
            //正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
            a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0]; 
            /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用）*/
            
            //代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧 https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
            var e = new MouseEvent('click', ( true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));  
            //派遣后，它将不再执行任何操作。执行保存到本地
            a.dispatchEvent(e); 
            //释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
            URL.revokeObjectURL(url);  
        },

        saveImg(imgBaseSrc) {
            let img = new Image()
            img.src = imgBaseSrc //这里是图片的src
            img.crossOrigin = 'anonymous' //The opeartaion is insecure , 其他跨域问题自行代理解决.
            img.onload = function() {
                let canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0)
                let imgBase64Data = canvas.toDataURL('image/jpeg', 1) //这里就拿到了base64

                /*
                h5中postMessage虽然会立即提交信息，但是小程序并不会立即受理。小程序webview上的监听函数，只会在特定时机触发并接收到消息。
                即postMessage所有的消息都只能等得分享或webview的生命周期结束时才会被触发，它是一个消息队列。
                在保存图片时，可以立即触发它的返回事件。
                */
                wx.miniProgram.postMessage({
                    data: {
                        imgData: imgBase64Data // 刚才拿到的base64 数据
                    }
                })
                wx.miniProgram.navigateBack({ delta: 1 }) // 注意：只有执行此事件后，才能在webview的事件监听中接收到信息
            }
        }
    },
    mounted() { 
        let url = 'https://m.sxdjcq.com/invite?siteInviteId=' + this.$route.query.memberId
        console.log(url,'111')
        this.createQrcode(url) //这里放的是二维码地址
    },
};

</script>
  
<style lang="less" scoped>
.wrap {
    position: relative;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    background-color: #F3FBFF;
}
.bgImage{
    background-repeat: no-repeat;
    background-size: 100vw 100vw/750*1334;
    width: 100vw;
    height: 100vw/750*1334;
}

.baocun{
  display: block;
  width: 80%;
  height: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  position: fixed;
  bottom: 30px;
  left: 10%;
  background: #30A4D7;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  border: 0;
}

.qrcode{
    display: flex;
    justify-content: center;
    padding-top: 100vw/750*1334/667*433;
}

.imgStyle{
    border-width: 4px;
    border-style: dashed;
    border-color: #30A4D7;
    padding: 4px;
}



</style>
  